import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Slider from "react-slick";
import abf from './assets/abf.png';  
import devblog from './assets/devblog1.jpg'; 
import forgedhub from './assets/forgedhub1.jpg'; 
import monkeydev from './assets/monkedev1.jpg';
import codecraftman from './assets/codecraftsman1.jpg';  
import hofors from './assets/hoforsbgk1.jpg';  
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';

const CustomerItem = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  
  border-radius: 12px;
  background-color: #ffffff;
  text-align: center;
  width: 400px;
  box-sizing: border-box; /* Inkludera padding och border i elementets bredd */
`;

const CustomerImage = styled.img`
  width: 100%;
  max-width: 100%; /* Bilden ska använda full bredd av sin container, utan att gå utanför */
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  display: block; /* Gör att bilden inte får något extra utrymme under sig */
  margin-left: auto;
  margin-right: auto; /* Centrerar bilden inom sin container */
`;

const CustomerText = styled.p`
  margin-top: 10px;
  font-size: 20px;
  color: #00000;
`;
function Popup({ content, closePopup }) {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    category: 'App',
    description: ''
  });
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    const templateParams = {
      name: formValues.name,
      email: formValues.email,
      category: formValues.category,
      description: formValues.description
    };

    emailjs.send('CodeForged', 'template_feezjfu', templateParams, '0_65CDZK2b9-yzfnG')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setIsSending(false);
        setIsSent(true);
        setFormValues({
          name: '',
          email: '',
          category: 'App',
          description: ''
        });
        setTimeout(() => {
          setIsSent(false);
          closePopup();
        }, 2000); // Stänger popupen efter 2 sekunder
      }, (err) => {
        console.log('FAILED...', err);
        setIsSending(false);
      });
  };

  const renderContent = (content) => {
    switch (content) {
      case "Nyheter":
        return (
          <>
            <h2>Nyheter</h2>
            <div className="contact-info">
            <CustomerImage src={abf} alt="ABF" />
            </div>
          </>
        );
      case "Kontakt":
        return (
          <>
            <h2>Kontakt</h2>
            <div className="contact-info">
              <p>Christer Holm</p>
              <p>Edskevägen 3B</p>
              <p>info@codeforged.se</p>
              <p>0736550614</p>
            </div>
          </>
        );
      case "Kunder":
        // Flytta settings-objektet hit
        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1, // Ändra till 1 för att visa en bild åt gången
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        };

        return (
          <>
            <h2>Kunder</h2>
            <Slider {...settings}>
  <CustomerItem>
    <a href="https://www.hoforsbgk.se">
      <CustomerImage src={hofors} alt="Hofors BGK" />
    </a>
    <CustomerText>Hofors BGK</CustomerText>
  </CustomerItem>
  <CustomerItem>
    <a href="https://www.devblog.se">
      <CustomerImage src={devblog} alt="Devblog" />
    </a>
    <CustomerText>DevBlog</CustomerText>
  </CustomerItem>
  <CustomerItem>
    <a href="https://www.forgedhub.se">
      <CustomerImage src={forgedhub} alt="forgedehub" />
    </a>
    <CustomerText>ForgedHub</CustomerText>
  </CustomerItem>
  <CustomerItem>
    <a href="https://www.monkeydev.se">
      <CustomerImage src={monkeydev} alt="Monkeydev" />
    </a>
    <CustomerText>Monkeydev</CustomerText>
  </CustomerItem>
  <CustomerItem>
    <a href="https://www.codecraftsman.se">
      <CustomerImage src={codecraftman} alt="CodeCraftsMan" />
    </a>
    <CustomerText>CodeCraftMan</CustomerText>
  </CustomerItem>
</Slider>
          </>
        );
      case "Offertförfrågan":
        return (
          <>
            <h2>Offertförfrågan</h2>
            <form className="quote-form" onSubmit={handleSubmit}>
              <label>Namn:</label>
              <input type="text" name="name" value={formValues.name} onChange={handleInputChange} required />
              <label>Email:</label>
              <input type="email" name="email" value={formValues.email} onChange={handleInputChange} required />
              <label>Välj typ av offert:</label>
              <select name="category" value={formValues.category} onChange={handleInputChange}>
                <option value="App">App</option>
                <option value="Webbsida">Webbsida</option>
                <option value="Script">Script</option>
                <option value="Wordpress">Wordpress</option>
                <option value="Domän">Domän</option>
                <option value="Webbhotel">Webbhotel</option>
                
              </select>
              <label>Beskrivning:</label>
              <textarea name="description" value={formValues.description} onChange={handleInputChange} rows="4" />
              <button type="submit">Skicka offertförfrågan</button>
            </form>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {content && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={closePopup}>&times;</span>
            {renderContent(content)}
          </div>
        </div>
      )}
      {isSending && (
        <div className="modal">
          <div className="modal-content">
            <h2>Skickar...</h2>
            <p>Din offertförfrågan skickas nu.</p>
          </div>
        </div>
      )}
      {isSent && (
        <div className="modal">
          <div className="modal-content">
            <h2>Skickat!</h2>
            <p>Din offertförfrågan har skickats.</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;


