import React, { useState } from 'react';
import './App.css';

import Popup from './Popup';

function App() {
  const [popupContent, setPopupContent] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const showPopup = (content) => {
    setPopupContent(content);
    setMenuOpen(false);  // Stänger menyn när popup visas
  };

  const closePopup = () => {
    setPopupContent(null);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="container">
      <header className="header">
        <img src="/logo.png" alt="CodeForged Logo" className="logo" />
        <button className="menu-toggle" onClick={toggleMenu}>
          &#9776;
        </button>
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
          <ul className="nav-list">
          <li className="nav-item"><a href="#contact" onClick={() => showPopup("Nyheter")}>Nyheter</a></li>
            <li className="nav-item"><a href="#contact" onClick={() => showPopup("Kontakt")}>Kontakt</a></li>
            <li className="nav-item"><a href="#projects" onClick={() => showPopup("Kunder")}>Kunder</a></li>
            <li className="nav-item"><a href="#quote" onClick={() => showPopup("Offertförfrågan")}>Offertförfrågan</a></li>
          </ul>
        </nav>
      </header>
      <Popup content={popupContent} closePopup={closePopup} />
    </div>
  );
}

export default App;;
